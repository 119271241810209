<template>
  <base-view
    title="Nóminas"
    description="Sube y mira los movimientos de las nóminas. Realiza la aprobación de presupuestos."
    icon="calendar"
  >
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <!-- El value permite que se muestre o no el modal para registrar nueva nómina.  -->
    <nominas-modal v-model="showModal" :mes="selectedMonth"></nominas-modal>

    <b-overlay :show="!Boolean(periodoActivo)">
      <template #overlay>
        <loading></loading>
      </template>

      <b-card no-body>
        <b-card-header>
          <check-authorization :requiresAuthorizations="['acceso periodos fiscales']">
            <router-link :to="{ name: 'PeriodosFiscales' }">
              <b-button size="sm">
                Cambiar periodo fiscal activo
              </b-button>
            </router-link>
          </check-authorization>
        </b-card-header>

        <div class="row my-3 p-3">
          <div class="col-md-4 mb-4" v-for="month in getMonths" :key="month.id">
            <a class="card lift h-100 my-cursor" @click="selectMonth(month.id)">
              <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="mr-3">
                    <i class="feather-xl text-primary mb-3" data-feather="calendar"></i>
                    <h5>{{ month.value }}</h5>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </base-view>
</template>

<script>
import NominasModal from '@/components/Nominas/NominasModal'
import { mapActions } from 'vuex'

export default {
  name: 'NominasView',

  components: {
    NominasModal
  },

  created () {
    this.init()
  },

  data () {
    return {
      showModal: false,

      selectedMonth: 0,

      //
      // Periodo fiscal activo
      periodoActivo: null
    }
  },

  computed: {
    getMonths () {
      return this.$store.getters['UIModule/getMonths'].slice(0, 12)
    }
  },

  methods: {
    ...mapActions('momentosContablesModule', ['aplicarPolizas']),

    /**
     * Inicializa el componente para seleccionar el periodo fiscal actualmente activo
     */
    async init () {
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      this.$store.commit(
        'momentosContablesModule/setPeriodoFiscalToImportPoliza',
        this.periodoActivo.id
      )

      this.$store.dispatch('momentosContablesModule/listarNominas', {
        periodo_fiscal_id: this.periodoActivo.id
      })
    },

    selectMonth (value) {
      this.selectedMonth = value

      this.showModal = true
    }
  }
}
</script>
